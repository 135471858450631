import axios from 'axios'

const BASE_URL = process.env.REACT_APP_API_BASE_URL

export const coreApiUnauthorizedInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})

export const coreApiAuthorizedInstance = axios.create({
  baseURL: BASE_URL
})

export const coreApiOnboardingInstance = axios.create({
  baseURL: BASE_URL
})

coreApiUnauthorizedInstance.interceptors.response.use(response => response.data)
coreApiAuthorizedInstance.interceptors.response.use(response => response.data)

coreApiAuthorizedInstance.interceptors.request.use(
  config => {
    let access_token = localStorage.getItem('accessToken')
    config.headers['Authorization'] = `Bearer ${access_token}`
    config.headers['Content-Type'] = 'application/json'
    config.headers['Accept'] = 'application/json'

    return config
  },
  (error: any) => {
    Promise.reject(error)
  }
)

coreApiUnauthorizedInstance.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json'
    config.headers['Accept'] = 'application/json'
    return config
  },
  (error: any) => {
    Promise.reject(error)
  }
)
