import React, { useRef, useEffect, useState, Fragment } from 'react'
import { useLocation } from 'react-router-dom'
import { ChartPieIcon, ClipboardDocumentIcon } from '@heroicons/react/24/solid'
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import { useMutation } from 'react-query'
import { ClientService } from '../../services/Client'
import moment from 'moment'
import Spinner from '../../components/Spinner'
import ExcelExport from '../../components/ExcelExport';
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import { hasPointerEvents } from '@testing-library/user-event/dist/utils';

import { Menu, Transition, Dialog } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { toast } from 'react-toastify';

ChartJS.register(ArcElement, Tooltip);

const Client = () => {

    const { state } = useLocation()
    const data = state

    const [userDetails, setUserDetails] = useState<any>()
    const [isLoaddingChartDataTotalAmount, setIsLoadingChartDataTotalAmount] = useState<boolean>(true)
    const [totalAmountTableData, setTotalAmountTableData] = useState<any>([])
    const [chartDataTotalAmount, setChartDataTotalAmount] = useState<any>()


    const [userProfits, setUserProfits] = useState<any>()
    const [isLoaddingChartDataRealizedProfit, setIsLoadingChartDataRealizedProfit] = useState<boolean>(true)
    const [RealizedProfitTableData, setRealizedProfitTableData] = useState<any>([])
    const [chartDataRealizedProfit, setChartDataRealizedProfit] = useState<any>()

    const [nextPayments, setNextPayments] = useState<any>([])
    const [selectedProject, setSelectedProject] = useState<string>('')
    const [selectedConcept, setSelectedConcept] = useState<string>('')
    const [selectedInvestmentDetails, setSelectedInvestmentDetails] = useState<any>([])
    const [selectedInvestment, setSelectedInvestment] = useState<string>('')
    const [selectedInvestmentReinvest, setSelectedInvestmentReinvest] = useState<boolean | null>(null)

    const [open, setOpen] = useState(false)

    const handleDataTotalAmount = () => {
        let dataChart = {}
        let dataLabels: string[] = []
        let dataAmounts: string[] = []

        if (userDetails && userDetails?.resume) {
            setTotalAmountTableData(userDetails.resume)
            userDetails.resume.map((r: any) => {
                dataLabels.push(r?.projectName)
                dataAmounts.push(r?.amount.native)
            })

            dataChart = {
                labels: dataLabels,
                datasets: [
                    {
                        label: '',
                        data: dataAmounts,
                        backgroundColor: [
                            '#0065F9',
                            '#66A3FB',
                            '#99C1FD',
                            '#BCB2FD',
                            '#8476ED',
                            '#6551F3',
                            '#9CEEFF',
                            '#6BE5FF',
                            '#08D4FF',
                        ],
                        borderColor: [
                            'rgb(31 41 55)',
                        ],
                        borderWidth: 0,
                    },
                ],
            }
        } else {
            dataChart = {
                labels: [],
                datasets: [
                    {
                        label: '',
                        data: [],
                        backgroundColor: [
                            '#0065F9',
                            '#66A3FB',
                            '#99C1FD',
                            '#BCB2FD',
                            '#8476ED',
                            '#6551F3',
                            '#9CEEFF',
                            '#6BE5FF',
                            '#08D4FF',
                        ],
                        borderColor: [
                            'rgb(31 41 55)',
                        ],
                        borderWidth: 0,
                    },
                ],
            }
        }
        setChartDataTotalAmount(dataChart)
        setIsLoadingChartDataTotalAmount(false)

        let next = []
        if (userDetails && userDetails?.paymentDetails) {
            next = userDetails.paymentDetails.filter((p: any) => p.paymentStatus == 'pending')
        }
        setNextPayments(next)
    }

    useEffect(() => {
        console.log(chartDataRealizedProfit);

    }, [chartDataRealizedProfit])

    const handleDataRealizedProfits = () => {
        let dataChart = {}
        let dataLabels: string[] = []
        let dataAmounts: string[] = []

        if (userProfits && userProfits?.profits) {
            setRealizedProfitTableData(userProfits.profits)
            userProfits.profits.map((r: any) => {
                dataLabels.push(r?.title)
                dataAmounts.push(r?.total.native)
            })

            dataChart = {
                labels: dataLabels,
                datasets: [
                    {
                        label: '',
                        data: dataAmounts,
                        backgroundColor: [
                            '#0065F9',
                            '#66A3FB',
                            '#99C1FD',
                            '#BCB2FD',
                            '#8476ED',
                            '#6551F3',
                            '#9CEEFF',
                            '#6BE5FF',
                            '#08D4FF',
                        ],
                        borderColor: [
                            'rgb(31 41 55)',
                        ],
                        borderWidth: 0,
                    },
                ],
            }
        } else {
            dataChart = {
                labels: [],
                datasets: [
                    {
                        label: '',
                        data: [],
                        backgroundColor: [
                            '#0065F9',
                            '#66A3FB',
                            '#99C1FD',
                            '#BCB2FD',
                            '#8476ED',
                            '#6551F3',
                            '#9CEEFF',
                            '#6BE5FF',
                            '#08D4FF',
                        ],
                        borderColor: [
                            'rgb(31 41 55)',
                        ],
                        borderWidth: 0,
                    },
                ],
            }
        }
        setChartDataRealizedProfit(dataChart)
        setIsLoadingChartDataRealizedProfit(false)
    }

    const options = {
        cutout: 70,
        responsive: true,
        legend: {
            display: false,
            position: "right"
        },
        tooltips: {
            callbacks: {
                label: (tooltipItem: any, data: any) => {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    var index = tooltipItem.index;
                    return dataset.labels[index] + ": " + dataset.data[index];
                }
            }
        }
    };

    const chartTotalAmountRef = useRef(null);
    const chartRealizedProfitRef = useRef(null);

    const { mutateAsync } = useMutation(ClientService.getUserDetails)
    const { mutateAsync: mutateAsyncProfits } = useMutation(ClientService.getUserProfits)

    const initRequest = async () => {
        if (data?.uuid) {
            await mutateAsync(data?.uuid).then(async (r) => {
                setUserDetails(r)
            })
                .catch((error) => {
                    console.log(error.response.data.message);
                })

            await mutateAsyncProfits(data?.uuid).then(async (r) => {
                setUserProfits(r)
            })
                .catch((error) => {
                    console.log(error.response.data.message);
                })
        }
    }

    const excelExportData = nextPayments.map((payment: any) => {
        return {
            proyecto: payment.projectName,
            concepto: payment.paymentType === 'capital' ? 'Capital' : 'Interes',
            fecha: moment(payment?.paymentDate).format('DD/MM/YYYY'),
            monto: payment.amount.formatted,
        }
    })

    useEffect(() => {
        initRequest()
    }, [])

    useEffect(() => {
        handleDataTotalAmount()
    }, [userDetails])

    useEffect(() => {
        handleDataRealizedProfits()
    }, [userProfits])

    const Hierarchy = () => {
        let hierarchy = data.hierarchy

        if (hierarchy.length > 0) {
            return (
                <div className='w-full flex flex-row'>
                    <div className="text-xs tracking-tight mt-1 mb-3 text-gray-400 flex flex-row gap-2 snap-x snap-mandatory w-full overflow-scroll pb-2">
                        {hierarchy.map((h: { uuid: string, name: string }, k: number) => (
                            <div className='flex flex-row gap-2 items-center snap-start' key={k}>
                                <span className='flex whitespace-nowrap'>
                                    {h.name}
                                </span>
                                {(k) < hierarchy.length - 1 && (
                                    <ArrowRightIcon className="h-3 w-3" />
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )
        } else {
            return <></>
        }
    }

    const classNames = (...classes: any) => {
        return classes.filter(Boolean).join(' ')
    }

    const ProjectsDropdown = () => {
        let projects: any = []
        nextPayments.map((p: any) => {
            if (p.projectName != null && !projects.includes(p.projectName)) projects.push(p.projectName)
        })

        return <Menu as="div" className="relative inline-block text-left w-full">
            <div>
                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    Proyecto
                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400 ml-auto" aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        <Menu.Item>
                            <span onClick={() => setSelectedProject('')}
                                className={classNames(
                                    selectedProject == '' ? 'bg-gray-900 text-gray-100' : 'text-gray-700',
                                    'block px-4 py-2 text-sm cursor-pointer'
                                )}
                            >
                                Todos
                            </span>
                        </Menu.Item>
                        {projects.map((p: string, k: number) => (
                            <Menu.Item key={k}>
                                <span onClick={() => setSelectedProject(p)}
                                    className={classNames(
                                        selectedProject == p ? 'bg-gray-900 text-gray-100' : 'text-gray-700',
                                        'block px-4 py-2 text-sm cursor-pointer'
                                    )}
                                >
                                    {p}
                                </span>
                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    }

    const ConceptsDropdown = () => {
        let concept: any = []
        nextPayments.map((p: any) => {
            if (p.paymentType != null && !concept.includes(p.paymentType)) concept.push(p.paymentType)
        })

        return <Menu as="div" className="relative inline-block text-left w-full">
            <div>
                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    Concepto
                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400 ml-auto" aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        <Menu.Item>
                            <span onClick={() => setSelectedConcept('')}
                                className={classNames(
                                    selectedProject == '' ? 'bg-gray-900 text-gray-100' : 'text-gray-700',
                                    'block px-4 py-2 text-sm cursor-pointer'
                                )}
                            >
                                Todos
                            </span>
                        </Menu.Item>
                        {concept.map((p: string, k: number) => (
                            <Menu.Item key={k}>
                                <span onClick={() => setSelectedConcept(p)}
                                    className={classNames(
                                        selectedProject == p ? 'bg-gray-900 text-gray-100' : 'text-gray-700',
                                        'block px-4 py-2 text-sm cursor-pointer'
                                    )}
                                >
                                    {p}
                                </span>
                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    }

    const { mutateAsync: mutateAsyncUserInvestmentDetails } = useMutation(ClientService.getProjectDetails)

    const openModalDetails = async (id: string, project: string, reinvest: boolean) => {
        if (project != 'Saldo disponible') {
            let params = { user_id: data.uuid, project_id: id }
            setSelectedInvestment(project)
            setSelectedInvestmentReinvest(reinvest)
            await mutateAsyncUserInvestmentDetails(params).then(async (r) => {
                setSelectedInvestmentDetails(r)
                setOpen(true)
            })
        }
    }

    useEffect(() => {
        if (!open) {
            setSelectedInvestmentDetails([])
            setSelectedInvestment('')
            setSelectedInvestmentReinvest(null)
        }
    }, [open])

    return (
        <>
            <header className="shadow">
                <div className="max-w-7xl px-4 pt-6 sm:px-6 lg:px-8 flex xs:flex-col sm:flex-row sm:items-center mx-auto">
                    <div className='flex flex-col'>
                        <span className="text-xs text-gray-400">Detalle del cliente</span>
                        <h1 className="text-3xl font-bold tracking-tight text-white mt-0 mb-3">{data.name}</h1>
                    </div>

                    <div className='sm:ml-auto flex xs:flex-col sm:flex-row gap-4 sm:mx-5 sm:mt-3'>
                        {data?.cvu && (
                            <div className='flex flex-col'>
                                <span className="text-xs text-gray-400">CVU</span>
                                <div className='flex flex-row'>
                                    <span className="text-xs tracking-tight text-white mt-0 mb-3">{data.cvu}</span>
                                    <ClipboardDocumentIcon className="h-5 w-5 -mt-1 ml-2 text-white cursor-pointer" aria-hidden="true" onClick={async () => {
                                        await navigator.clipboard.writeText(data.cvu)

                                        toast.success('CVU copiado con éxito', {
                                            position: "top-center",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                            theme: "light",
                                        });
                                    }} />
                                </div>
                            </div>
                        )}
                        {data?.alias && (
                            <div className='flex flex-col'>
                                <span className="text-xs text-gray-400">Alias</span>
                                <div className='flex flex-row'>
                                    <span className="text-xs tracking-tight text-white mt-0 mb-3">{data.alias}</span>
                                    <ClipboardDocumentIcon className="h-5 w-5 -mt-1 ml-2 text-white cursor-pointer" aria-hidden="true" onClick={async () => {
                                        await navigator.clipboard.writeText(data.alias)

                                        toast.success('Alias copiado con éxito', {
                                            position: "top-center",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                            theme: "light",
                                        });
                                    }} />
                                </div>
                            </div>
                        )}
                        {data?.cuit && (
                            <div className='flex flex-col'>
                                <span className="text-xs text-gray-400">CUIT</span>
                                <div className='flex flex-row'>
                                    <span className="text-xs tracking-tight text-white mt-0 mb-3">{data.cuit}</span>
                                    <ClipboardDocumentIcon className="h-5 w-5 -mt-1 ml-2 text-white cursor-pointer" aria-hidden="true" onClick={async () => {
                                        await navigator.clipboard.writeText(data.cuit)

                                        toast.success('CUIT copiado con éxito', {
                                            position: "top-center",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                            theme: "light",
                                        });
                                    }} />
                                </div>
                            </div>
                        )}
                    </div>

                    <div className='sm:ml-auto flex flex-col xs:w-full sm:w-auto'>
                        <span className="text-xs text-gray-400">Capital invertido</span>
                        <h1 className="text-2xl font-bold tracking-tight text-white mt-0 mb-0">{data.total_capital?.formatted}</h1>
                    </div>
                </div>
                {data.hierarchy.length > 0 && (
                    <div className="max-w-7xl px-4 sm:px-6 lg:px-8 flex mx-auto">
                        {Hierarchy()}
                    </div>
                )}
            </header>

            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg w-full">
                                    <div className="bg-gray-700">
                                        <div className="p-3">
                                            <div className="mt-3 text-center sm:text-left">
                                                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-white flex flex-row justify-center">
                                                    {selectedInvestment} {selectedInvestmentReinvest && '(Reinversión activada)'}
                                                    <div onClick={() => setOpen(false)} className="ml-auto bg-blue-600 text-white px-2 rounded-xl font-normal cursor-pointer">x</div>
                                                </Dialog.Title>
                                                <div className="my-2">
                                                    {selectedInvestmentDetails.length > 0 ? (
                                                        <table className="w-full text-sm text-left text-gray-400">
                                                            <thead className="text-xs uppercase text-white border-b-2 border-white">
                                                                <tr>
                                                                    <th scope="col" className="px-2 py-2">
                                                                        Fecha
                                                                    </th>
                                                                    <th scope="col" className="px-2 py-2 text-right">
                                                                        Monto
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {selectedInvestmentDetails.map((d: any, k: any) => (
                                                                    <tr key={k} className="border-b border-gray-400">
                                                                        <th scope="row" className="pr-2 py-4 font-medium whitespace-nowrap text-gray-400">
                                                                            {new Date(d?.confirmed_date).toLocaleDateString('es-AR')}
                                                                        </th>
                                                                        <td className="pl-2 py-4 text-right">
                                                                            {d?.amount?.formatted}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    ) : (
                                                        <div className='p-8'>
                                                            No hay información para mostrar
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            <main>
                <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 xs:px-4">
                    <div className='grid xs:grid-cols-1 md:grid-cols-2 gap-10'>
                        <div className='flex flex-col justify-center transform overflow-hidden rounded-2xl bg-gray-800 p-6 text-left align-middle transition-all opacity-100 scale-100'>
                            <div className='flex flex-row'>
                                <div className='flex flex-row center'>
                                    <ChartPieIcon className="text-white mr-2 h-6 w-6" aria-hidden="true" />
                                    <h3 className='text-white pb-5'>Total valorizados</h3>
                                </div>
                            </div>

                            <div className='flex xs:flex-col md:flex-row gap-10'>
                                {isLoaddingChartDataTotalAmount ? (
                                    <div className="w-full h-48 flex justify-center items-center">
                                        <Spinner />
                                    </div>
                                ) : (
                                    totalAmountTableData.length > 0 ? (
                                        <>
                                            <div className='relative xs:w-full md:w-1/3'>
                                                <div className='absolute w-full h-full flex justify-center items-center flex-col'>
                                                    <span className='text-white text-sm font-bold'>{data?.total_amount?.formatted}</span>
                                                </div>
                                                <div className='relative z1'>
                                                    <Doughnut
                                                        data={chartDataTotalAmount}
                                                        options={options}
                                                        ref={chartTotalAmountRef}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full flex flex-col">
                                                <div className='flex flex-row w-full border-b-2 border-blue-400 py-2'>
                                                    <span className='w-1/3 text-xs text-blue-400'>Detalle</span>
                                                    <span className='w-1/3 text-xs text-blue-400 text-center'>Reinvertir</span>
                                                    <span className='w-1/3 text-xs text-blue-400 text-right'>Monto</span>
                                                </div>
                                                <div className='h-40 overflow-auto'>
                                                    {totalAmountTableData.length > 0 && (
                                                        totalAmountTableData.map((d: any, k: any) => (
                                                            <div onClick={() => openModalDetails(d?.projectId, d?.projectName, d?.reinvest)} key={k} className='flex flex-row w-full border-b-2 border-gray-700 py-3 cursor-pointer hover:bg-black px-2'>
                                                                <span className='w-1/3 text-xs text-white'>{d?.projectName}</span>
                                                                <span className='w-1/3 text-xs text-white text-center'>{d?.reinvest ? 'Si' : d?.projectName == 'Saldo disponible' ? '' : 'No'}</span>
                                                                <span className='w-1/3 text-xs text-white text-right'>{d?.amount.formatted}</span>
                                                            </div>
                                                        ))
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="w-full h-48 flex justify-center items-center text-white">
                                            No existen datos para mostrar
                                        </div>
                                    )
                                )}
                            </div>
                        </div>

                        <div className='flex flex-col justify-center transform overflow-hidden rounded-2xl bg-gray-800 p-6 text-left align-middle transition-all opacity-100 scale-100'>
                            <div className='flex flex-row'>
                                <div className='flex flex-row center'>
                                    <ChartPieIcon className="text-white mr-2 h-6 w-6" aria-hidden="true" />
                                    <h3 className='text-white pb-5'>Intereses cobrados</h3>
                                </div>
                            </div>

                            <div className='flex xs:flex-col md:flex-row gap-10 items-center'>

                                {isLoaddingChartDataRealizedProfit ? (
                                    <div className="w-full h-48 flex justify-center items-center">
                                        <Spinner />
                                    </div>
                                ) : (

                                    RealizedProfitTableData.length > 0 ? (
                                        <>
                                            <div className='relative xs:w-full md:w-1/3'>
                                                <div className='absolute w-full h-full flex justify-center items-center flex-col'>
                                                    <span className='text-white text-sm font-bold'>{data?.realized_profit?.formatted}</span>
                                                </div>
                                                <div className='relative z1'>
                                                    <Doughnut
                                                        data={chartDataRealizedProfit}
                                                        options={options}
                                                        ref={chartRealizedProfitRef}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full flex flex-col">
                                                <div className='flex flex-row w-full border-b-2 border-blue-400 py-2'>
                                                    <span className='w-1/2 text-xs text-blue-400'>Detalle</span>
                                                    <span className='w-1/2 text-xs text-blue-400 text-right'>Monto</span>
                                                </div>
                                                <div className='h-40 overflow-auto'>
                                                    {RealizedProfitTableData.length > 0 && (
                                                        RealizedProfitTableData.map((d: any, k: any) => (
                                                            <div key={k} className='flex flex-row w-full border-b-2 border-gray-700 py-3'>
                                                                <span className='w-1/2 text-xs text-white'>{d?.title}</span>
                                                                <span className='w-1/2 text-xs text-white text-right'>{d?.total.formatted}</span>
                                                            </div>
                                                        ))
                                                    )}

                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="w-full h-48 flex justify-center items-center text-white">
                                            No existen datos para mostrar
                                        </div>
                                    )
                                )}

                            </div>
                        </div>

                    </div>

                    {isLoaddingChartDataTotalAmount ? (
                        <div className="w-full h-48 flex justify-center items-center flex-col">
                            <Spinner /> <h3 className="mt-5 text-xl tracking-tight text-white">Cargando próximos pagos</h3>
                        </div>
                    ) : (
                        <>
                            <div className="relative overflow-x-auto mt-10 flex items-center mb-4">
                                <h3 className="text-2xl font-bold tracking-tight text-white">Próximos pagos</h3>
                            </div>

                            <ExcelExport excelData={excelExportData} fileName={'Próximos pagos'} />

                            <div className="relative overflow-x-auto mt-5 mb-10">
                                <div className='flex flex-col'>
                                    <table className="w-full text-sm text-left text-gray-400">
                                        <thead className="text-xs uppercase bg-gray-700 text-gray-400">
                                            <tr>
                                                <th scope="col" className="px-6 py-3">
                                                    {ProjectsDropdown()}
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-center">
                                                    {ConceptsDropdown()}
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-center">
                                                    Fecha
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-center">
                                                    TNA
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-right">
                                                    Monto
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {nextPayments && nextPayments.length > 0 ? (
                                                nextPayments.map((np: any, k: any) => (
                                                    (selectedProject == '' || selectedProject == np?.projectName) && (selectedConcept == '' || selectedConcept == np?.paymentType)) && (
                                                        <tr key={k} className="border-b bg-gray-800 border-gray-700 max-h-10">
                                                            <td scope="row" className="px-6 py-4 font-medium whitespace-nowrap text-white">
                                                                {np?.projectName}
                                                            </td>
                                                            <td className="px-6 py-4 text-center">
                                                                {np?.paymentType == 'capital' ? 'Capital' : 'Interes'}
                                                            </td>
                                                            <td className="px-6 py-4 text-center">
                                                                {moment(np?.paymentDate).format('DD/MM/YYYY')}
                                                            </td>
                                                            <td className="px-6 py-4 text-center">
                                                                {np?.returnPercentage}%
                                                            </td>
                                                            <td className="px-6 py-4 text-right">
                                                                {np?.amount.formatted}
                                                            </td>
                                                        </tr>
                                                    ))
                                            ) : (
                                                <tr className="border-t border-gray-800">
                                                    <td colSpan={4} className="text-center p-10">
                                                        No existen próximos pagos
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                    )}

                </div>
            </main>
        </>
    )
}

export default Client
