import React, { useEffect, useRef, useState, useContext, FC } from 'react'
import { useMutation } from 'react-query'
import { ProspectoService } from '../../services/Prospecto'
import Spinner from '../../components/Spinner'
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import IProspectoUser from '../../interfaces/core-api/prospecto';

import {
    TERipple,
    TEModal,
    TEModalDialog,
    TEModalContent,
    TEModalHeader,
    TEModalBody,
    TEModalFooter,
    TEInput
} from "tw-elements-react";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const schema: any = Yup
    .object({
        email: Yup.string().email('Ingrese un email válido'),
        phoneNumber: Yup.string(),
        firstName: Yup.string(),
        lastName: Yup.string(),
    })
    .required()

const AddProspecto: FC<{ refreshData: () => Promise<void> }> = ({ refreshData }) => {

    const [showModal, setShowModal] = useState(false);
    const [isLoading, setisLoading] = useState(false);

    const { control, handleSubmit, formState: { errors, isValid }, setValue, resetField, register } = useForm<IProspectoUser>({
        resolver: yupResolver(schema),
        mode: 'onChange'
    });

    const { mutateAsync } = useMutation(ProspectoService.addPotentialUser)

    const onSubmit = async (data: IProspectoUser) => {
        console.log(data);
        if (data.email === '' && data.phoneNumber === '') {
            toast.error('Debe ingresar al menos una forma de contacto (Email o Teléfono)', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return
        }
        setisLoading(true)
        await mutateAsync(data).then(async (r) => {
            setShowModal(false)
            toast.success('Prospecto cargado con éxito', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            })
            resetField("email")
            resetField("firstName")
            resetField("lastName")
            resetField("phoneNumber")
            setisLoading(false)
            refreshData()
        })
            .catch((error) => {
                setisLoading(false)
                toast.error(error.response.data.message[0] || 'Error al agregar nuevo prospecto', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            })
    };

    return (
        <>
            <TERipple rippleColor="white" className="xs:w-full sm:w-auto">
                <button
                    type="button"
                    className="rounded-2xl justify-center items-center px-3 py-2 bg-black text-white w-full"
                    onClick={() => setShowModal(true)}
                >
                    Nuevo prospecto
                </button>
            </TERipple>

            {/* <!-- Modal --> */}
            <TEModal show={showModal} setShow={setShowModal}>
                <TEModalDialog>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <TEModalContent>
                            <TEModalHeader>
                                {/* <!--Modal title--> */}
                                <h5 className="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200">
                                    Cargar nuevo prospecto
                                </h5>
                                {/* <!--Close button--> */}
                                <button
                                    type="button"
                                    className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                                    onClick={() => setShowModal(false)}
                                    aria-label="Close"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className="h-6 w-6"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                </button>
                            </TEModalHeader>
                            {/* <!--Modal body--> */}
                            <TEModalBody className="p-6">
                                <div className='mb-8'>
                                    <TEInput
                                        type="text"
                                        id="firstName"
                                        label="Nombre"
                                        {...register("firstName")}
                                        className="text-black"
                                        disabled={isLoading}
                                    >
                                        <div className="mt-1 absolute w-full text-xs text-red-600">
                                            {errors?.firstName && (errors.firstName.message)}
                                        </div>
                                    </TEInput>
                                </div>
                                <div className='mb-8'>
                                    <TEInput
                                        type="text"
                                        id="lastName"
                                        label="Apellido"
                                        {...register("lastName")}
                                        className="text-black"
                                        disabled={isLoading}
                                    >
                                        <div className="mt-1 absolute w-full text-xs text-red-600">
                                            {errors?.lastName && (errors.lastName.message)}
                                        </div>
                                    </TEInput>
                                </div>
                                <div className='mb-8'>
                                    <TEInput
                                        type="text"
                                        id="email"
                                        label="E-mail"
                                        {...register("email")}
                                        className="text-black"
                                        disabled={isLoading}
                                    >
                                        <div className="mt-1 absolute w-full text-xs text-red-600">
                                            {errors?.email && (errors.email.message)}
                                        </div>
                                    </TEInput>
                                </div>
                                <div className='mb-8'>
                                    <div className="relative mb-4 flex flex-wrap items-stretch">
                                        <span
                                            className="text-black flex items-center whitespace-nowrap rounded-s border border-e-0 border-solid border-neutral-200 px-3 py-[0.25rem] text-center text-base font-normal leading-[1.6] text-surface dark:border-white/10 dark:text-white"
                                            id="basic-addon1"
                                        >+54</span>
                                        <input
                                            type="text"
                                            className="relative m-0 block text-black flex-auto rounded-e border border-solid border-neutral-200 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-surface outline-none transition duration-200 ease-in-out placeholder:text-neutral-500 focus:z-[3] focus:border-primary focus:shadow-inset focus:outline-none motion-reduce:transition-none dark:border-white/10 dark:text-white dark:placeholder:text-neutral-200 dark:autofill:shadow-autofill dark:focus:border-primary"
                                            placeholder="Teléfono"
                                            aria-label="Teléfono"
                                            aria-describedby="basic-addon1"
                                            id="phoneNumber"
                                            {...register("phoneNumber")}
                                            disabled={isLoading}
                                        />
                                        <div className="mt-1 absolute w-full text-xs text-red-600">
                                            {errors?.phoneNumber && (errors.phoneNumber.message)}
                                        </div>
                                    </div>

                                    <div className="mt-1 absolute w-full text-xs text-red-600">
                                        {errors?.phoneNumber && (errors.phoneNumber.message)}
                                    </div>
                                </div>

                            </TEModalBody>
                            <TEModalFooter>
                                {isLoading && (
                                    <div className='flex flex-row justify-center items-center'>
                                        <Spinner /> <div className='ml-0 mr-5 text-sm'>Guardando</div>
                                    </div>
                                )}
                                <TERipple rippleColor="light">
                                    <button
                                        type="button"
                                        className="inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
                                        onClick={() => setShowModal(false)}
                                        disabled={isLoading}
                                    >
                                        Cerrar
                                    </button>
                                </TERipple>
                                <TERipple rippleColor="light">
                                    <button
                                        type="submit"
                                        className="ml-5 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                                        disabled={isLoading}
                                    >
                                        Guardar
                                    </button>
                                </TERipple>
                            </TEModalFooter>
                        </TEModalContent>
                    </form>
                </TEModalDialog>
            </TEModal>

        </>
    )
}

export default AddProspecto