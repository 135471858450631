import React, { useContext } from 'react'
import { Routes, Route, Navigate, Outlet } from 'react-router-dom'
import { AuthContext } from './context/AuthContext'
import ClientDetails from "./pages/ClientDetails"
import Clients from "./pages/Clients"
import Prospecto from "./pages/Prospecto"
import Error from './pages/Error'
import Home from "./pages/Home"
import Login from "./pages/Login"
import Layout from './components/Layout'

const PrivateRoutes = () => {
  const { authenticated } = useContext(AuthContext)

  if (!authenticated) return <Navigate to='/login' replace />

  return <Outlet />
}

const App = () => {
  return (
    <Routes>
      <Route path='/login' element={<Login />} />
      <Route element={<PrivateRoutes />}>
        <Route path='/' element={<Layout />}>
          <Route
            index
            element={<Home />}
          />
          <Route path="/cliente" element={<ClientDetails />} />
          <Route path="/mis-clientes" element={<Clients />} />
          <Route path="/prospecto" element={<Prospecto />} />
          <Route path="*" element={<Error />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default App;
