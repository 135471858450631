import React from 'react';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { ArrowDownOnSquareIcon } from '@heroicons/react/24/outline'

const ExcelExport = ({ excelData, fileName }: any) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';



    const exportToExcel = async (fileName: any) => {
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });

        FileSaver.saveAs(data, fileName + fileExtension)
    }

    return (
        <div className='content-center items-center flex-row flex mb-4 justify-end'>
            <button onClick={(e) => exportToExcel(fileName)}
                className='flex slate-50 bg-gray-800 rounded-2xl justify-center items-center px-3 py-2 xs:w-full sm:w-auto'
            >
                <ArrowDownOnSquareIcon className="h-5 w-5" color='white' />
                <text className='text-center text-sm text-white ml-2'>Descargar</text>
            </button>
        </div>
    )
}

export default ExcelExport