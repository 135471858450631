import React, { useContext, useEffect } from 'react'
import Logo from '../../assets/images/logo.png'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
    username: yup.string().email("Ingresá un email válido").required("Ingresá tu email"),
    password: yup.string().required("La contraseña es requerida"),
});

const Login = () => {

    const { login, authenticated, loginError } = useContext(AuthContext)
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmitHandler = (data: any) => {
        // console.log(data, 'este es el data')
        login(data.username, data.password)
        reset();
    };

    useEffect(() => {
        if (authenticated) navigate('/')
    }, [authenticated, navigate])

    return (
        <>
            <div className="min-h-full bg-gray-700 flex justify-center items-center">
                <div className='flex flex-col justify-center transform overflow-hidden rounded-2xl bg-gray-800 p-6 transition-all xs:w-full sm:w-1/4 m-4'>
                    <div className='flex flex-row items-center'>
                        <div className='flex flex-row items-center justify-center w-full'>
                            <img className='h-44' src={Logo} alt="Yont" />
                        </div>
                    </div>

                    <div className="mb-10 sm:mx-auto sm:w-full sm:max-w-sm ">
                        <form className="space-y-6" onSubmit={handleSubmit(onSubmitHandler)}>
                            <div>
                                <label className="block text-sm font-medium leading-6 text-white">E-mail</label>
                                <div className="mt-2">
                                    <input {...register("username")} id="username" name="username" type="email" className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6" />
                                    <p className='text-red-500 text-sm mt-2'>{errors.username?.message}</p>
                                </div>
                            </div>

                            <div>
                                <div className="flex items-center justify-between">
                                    <label className="block text-sm font-medium leading-6 text-white">Contraseña</label>
                                </div>
                                <div className="mt-2">
                                    <input {...register("password")} id="password" name="password" type="password" className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6" />
                                    <p className='text-red-500 text-sm mt-2'>{errors.password?.message}</p>
                                </div>
                            </div>

                            <div>
                                <button type="submit" className="flex w-full justify-center rounded-md bg-blue-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Ingresar</button>
                                {loginError && (
                                    <p className='text-red-500 text-sm mt-2'>Email y/o contraseña incorrectos</p>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login