import React, { useEffect, useRef, useState, useContext } from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext'
import { useMutation } from 'react-query'
import { ProspectoService } from '../../services/Prospecto'
import Spinner from '../../components/Spinner'
import AddProspecto from './AddProspecto';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Prospecto = () => {

    const { me } = useContext(AuthContext)
    const navigate = useNavigate();

    const refSearch = useRef<any>(null)
    const [potencialUsers, setPotencialUsers] = useState<any>([])
    const [filteredPotencialUsers, setFilteredPotencialUsers] = useState<any>([])
    const [isLoadding, setIsLoading] = useState<boolean>(true)

    const filter = () => {
        let allData = potencialUsers
        let query = refSearch.current?.value || ''
        if (query.length <= 2) {
            setFilteredPotencialUsers(allData)
        } else {
            let filteredData = allData.filter((d: any) => d.email.toLowerCase().includes(query.toLowerCase()))
            setFilteredPotencialUsers(filteredData)
        }
    }

    const { mutateAsync } = useMutation(ProspectoService.getPotentialUsers)

    const initRequest = async () => {
        setIsLoading(true)
        await mutateAsync().then(async (r) => {
            setPotencialUsers(r)
            setFilteredPotencialUsers(r)
            setIsLoading(false)
        })
            .catch((error) => {
                console.log(error.response.data.message);
                setIsLoading(false)
            })
    }

    useEffect(() => {
        initRequest()
    }, [])

    return (
        <>
            <header className="shadow">
                <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8 flex center xs:flex-col sm:flex-row">
                    <h1 className="text-3xl font-bold tracking-tight text-white mt-0 mb-3">Prospecto</h1>
                    <div className="sm:ml-auto">
                        <div className='flex xs:flex-col sm:flex-row'>
                            <div className='sm:mr-5 xs:mb-4'>
                                <AddProspecto refreshData={initRequest} />
                            </div>
                            <div className="h-10 flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                <span className="flex select-none items-center px-3 text-white sm:text-sm">
                                    <MagnifyingGlassIcon className="block h-5 w-5" aria-hidden="true" />
                                </span>
                                <input ref={refSearch} onChange={filter} type="text" name="search" id="search" className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-white placeholder:text-gray-400 ring-0 focus:ring-0 sm:text-sm sm:leading-6" placeholder="Buscar por e-mail" />
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <main>
                <div className="mx-auto max-w-7xl py-6 xs:px-4 sm:px-6 lg:px-8">

                    {isLoadding ? (
                        <div className="w-full h-48 flex justify-center items-center flex-col">
                            <Spinner /> <h3 className="mt-5 text-xl tracking-tight text-white">Cargando prospecto</h3>
                        </div>
                    ) : (
                        <>
                            <div className="relative overflow-x-auto">
                                {filteredPotencialUsers.length > 0 ? (
                                    <div className='flex flex-col'>
                                        <table className="w-full text-sm text-left text-gray-500 text-gray-400">
                                            <thead className="text-xs uppercase bg-gray-700 text-gray-400">
                                                <tr>
                                                    <th scope="col" className="px-6 py-3">
                                                        Nombre
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        Apellido
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        E-mail
                                                    </th>
                                                    <th scope="col" className="px-6 py-3">
                                                        Teléfono
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredPotencialUsers.map((d: any, k: any) => (
                                                    <tr key={k} className="border-b bg-gray-800 border-gray-700">
                                                        <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap text-white">
                                                            {d?.firstName}
                                                        </th>
                                                        <td className="px-6 py-4">
                                                            {d?.lastName}
                                                        </td>
                                                        <td className="px-6 py-4">
                                                            {d?.email}
                                                        </td>
                                                        <td className="px-6 py-4">
                                                            {d?.phoneNumber}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <div className="w-full h-48 flex justify-center items-center text-white">
                                        No existen prospecto para mostrar
                                    </div>
                                )}

                            </div>
                        </>
                    )}
                </div>
            </main>
        </>
    )
}

export default Prospecto