import { coreApiAuthorizedInstance } from './axiosInstance'

export class ClientService {
    public static getUserDetails = async (id: string): Promise<any> => {
        return await coreApiAuthorizedInstance.get(`/advisors/referrals/user-details/${id}`, {
            validateStatus: function (status: number) {
                return status < 300
            },
        })
    }

    public static getUserProfits = async (id: string): Promise<any> => {
        return await coreApiAuthorizedInstance.get(`/advisors/referrals/user-profits/${id}`, {
            validateStatus: function (status: number) {
                return status < 300
            },
        })
    }

    public static getProjectDetails = async (data: {user_id: string, project_id: string}): Promise<any> => {
        return await coreApiAuthorizedInstance.get(`/advisors/referrals/user-details/investments/${data.user_id}/${data.project_id}`, {
            validateStatus: function (status: number) {
                return status < 300
            },
        })
    }
}
