import { coreApiAuthorizedInstance } from './axiosInstance'

export class ReportsService {
    public static getInvestmentsByRound = async (): Promise<any> => {
        return await coreApiAuthorizedInstance.get(`/advisors/referrals/investments-by-round`, {
            validateStatus: function (status: number) {
                return status < 300
            },
        })
    }

    public static getReferralsByRound = async (): Promise<any> => {
        return await coreApiAuthorizedInstance.get(`/advisors/referrals/referrals-by-round`, {
            validateStatus: function (status: number) {
                return status < 300
            },
        })
    }

    public static getTotals = async (): Promise<any> => {
        return await coreApiAuthorizedInstance.get(`/advisors/referrals/totals`, {
            validateStatus: function (status: number) {
                return status < 300
            },
        })
    }
}
