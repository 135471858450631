import React, { useEffect, useRef, useState, useContext, Fragment } from 'react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext'
import { useMutation } from 'react-query'
import { DashboardService } from '../../services/Dashboard'
import Spinner from '../../components/Spinner'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ExcelExport from '../../components/ExcelExport';
//import { ReferralResponseType } from '../../interfaces/core-api/dashboard';

import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

const Clients = () => {

    const { me } = useContext(AuthContext)
    const navigate = useNavigate();


    const refSearch = useRef<any>(null)
    const [referrals, setReferrals] = useState<any>([])
    const [filteredReferrals, setFilteredReferrals] = useState<any>([])
    const [selectedParent, setSelectedParent] = useState<string>('')

    const [isLoadding, setIsLoading] = useState<boolean>(true)

    const filter = () => {
        let allData = referrals
        let query = refSearch.current?.value || ''
        if (query.length <= 2) {
            setFilteredReferrals(allData)
        } else {
            let filteredData = allData.filter((d: any) => d.name.toLowerCase().includes(query.toLowerCase()))
            setFilteredReferrals(filteredData)
        }
    }

    const copyReferal = async () => {
        let referal = me?.referralCode
        await navigator.clipboard.writeText(referal)

        toast.success('Código de referido copiado con éxito', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    }

    const { mutateAsync } = useMutation(DashboardService.getReferrals)

    const initRequest = async () => {
        setIsLoading(true)
        await mutateAsync().then(async (r) => {
            let referrals = r?.referrals ? r.referrals : {}
            setReferrals(referrals)
            setFilteredReferrals(referrals)
            setIsLoading(false)
        })
            .catch((error) => {
                console.log(error.response.data.message);
                setIsLoading(false)
            })
    }

    useEffect(() => {
        initRequest()
    }, [])

    const goToClientDetail = (client: any) => {
        navigate('/cliente', { state: client });
    }

    const excelExportData = filteredReferrals.map((client: any) => {
        return {
            cliente: client.name,
            total_valorizado: client.realized_profit.formatted,
            total_invertido: client.total_capital.formatted,
            intereses_cobrados: client.total_amount.formatted,
            total_disponible: client.total_bank_amount.formatted
        }
    })

    const classNames = (...classes: any) => {
        return classes.filter(Boolean).join(' ')
    }

    const ParentsDropdown = () => {
        let parents: any = []
        filteredReferrals.map((fr: any) => {
            if (fr.parent_name != null && !parents.includes(fr.parent_name)) parents.push(fr.parent_name)
        })

        return <Menu as="div" className="relative inline-block text-left w-full">
            <div>
                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    Productor
                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400 ml-auto" aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        <Menu.Item>
                            <span onClick={() => setSelectedParent('')}
                                className={classNames(
                                    selectedParent == '' ? 'bg-gray-900 text-gray-100' : 'text-gray-700',
                                    'block px-4 py-2 text-sm cursor-pointer'
                                )}
                            >
                                Todos
                            </span>
                        </Menu.Item>
                        {parents.map((p: string, k: number) => (
                            <Menu.Item key={k}>
                                <span onClick={() => setSelectedParent(p)}
                                    className={classNames(
                                        selectedParent == p ? 'bg-gray-900 text-gray-100' : 'text-gray-700',
                                        'block px-4 py-2 text-sm cursor-pointer'
                                    )}
                                >
                                    {p}
                                </span>
                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    }

    return (
        <>
            <header className="shadow">
                <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8 flex xs:flex-col md:flex-row center">
                    <h1 className="text-3xl font-bold tracking-tight text-white mt-0 mb-3">Mis clientes</h1>
                    <div className="md:ml-auto">
                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <span className="flex select-none items-center px-3 text-white sm:text-sm">
                                <MagnifyingGlassIcon className="block h-5 w-5" aria-hidden="true" />
                            </span>
                            <input ref={refSearch} onChange={filter} type="text" name="username" id="username" className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-white placeholder:text-gray-400 ring-0 focus:ring-0 sm:text-sm sm:leading-6" placeholder="Buscar..." />
                        </div>
                    </div>
                </div>
            </header>
            <main>
                <div className="mx-auto max-w-7xl py-6 xs:px-4 sm:px-6 lg:px-8">
                    {isLoadding ? (
                        <div className="w-full h-48 flex justify-center items-center flex-col">
                            <Spinner /> <h3 className="mt-5 text-xl tracking-tight text-white">Cargando clientes</h3>
                        </div>
                    ) : (
                        <>
                            <ExcelExport excelData={excelExportData} fileName={'Listado clientes'} />

                            <div className="relative overflow-x-auto">
                                {filteredReferrals.length > 0 ? (
                                    <div className='flex flex-col '>
                                        <div className="overflow-x-auto">
                                            <table className="w-full text-sm text-left text-gray-400">
                                                <thead className="text-xs uppercase bg-gray-700 text-gray-400">
                                                    <tr>
                                                        <th scope="col" className="px-2 py-2">
                                                            Cliente
                                                        </th>
                                                        <th scope="col" className="px-2 py-2 text-right">
                                                            Total Valorizado
                                                        </th>
                                                        <th scope="col" className="px-2 py-2 text-right">
                                                            Total Invertido
                                                        </th>
                                                        <th scope="col" className="px-2 py-2 text-right">
                                                            Intereses Cobrados
                                                        </th>
                                                        <th scope="col" className="px-2 py-2 text-right">
                                                            Total Disponible
                                                        </th>
                                                        <th scope="col" className="pl-3 py-2 text-right">
                                                            {ParentsDropdown()}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredReferrals.map((d: any, k: any) => (
                                                        (selectedParent == '' || selectedParent == d?.parent_name) && (
                                                            <tr key={k} className="border-b bg-gray-800 border-gray-700 hover:bg-black hover:cursor-pointer" onClick={() => {
                                                                goToClientDetail(d)
                                                            }}>
                                                                <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap text-white">
                                                                    {d?.name}
                                                                </th>
                                                                <td className="px-6 py-4 text-right">
                                                                    {d?.total_amount?.formatted}
                                                                </td>
                                                                <td className="px-6 py-4 text-right">
                                                                    {d?.total_capital?.formatted}
                                                                </td>
                                                                <td className="px-6 py-4 text-right">
                                                                    {d?.realized_profit?.formatted}
                                                                </td>
                                                                <td className="px-6 py-4 text-right">
                                                                    {d?.total_bank_amount?.formatted}
                                                                </td>
                                                                <td className="px-6 py-4 text-right">
                                                                    {d?.parent_name}
                                                                </td>
                                                            </tr>
                                                        )
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="w-full h-48 flex justify-center items-center text-white">
                                        No existen clientes para mostrar
                                    </div>
                                )}

                            </div>
                        </>
                    )}
                </div>
            </main>
        </>
    )
}

export default Clients