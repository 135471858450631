//import { ReferralResponseType } from '../interfaces/core-api/dashboard'
import { coreApiAuthorizedInstance } from './axiosInstance'

export class DashboardService {
    public static getReferrals = async (): Promise<any> => {
        return await coreApiAuthorizedInstance.get('/advisors/referrals?page=0&limit=1000000', {
            validateStatus: function (status: number) {
                return status < 300
            },
        })
    }
}
