import { createContext, ReactNode, useState, useEffect } from 'react'
import axios from 'axios'

const BASE_URL = process.env.REACT_APP_API_BASE_URL

type Props = {
    children?: ReactNode;
}

type Ime = {
    email: string,
    firstName: string,
    lastName: string,
    referralCode: string
}

type IAuthContext = {
    authenticated: boolean;
    setAuthenticated: (newState: boolean) => void
    accessToken: string;
    setAccessToken: (newState: string) => void,
    loginError: boolean;
    setLoginError: (newState: boolean) => void,
    login: (username: string, password: string) => void,
    me: Ime,
    setMe: (newState: Ime) => void,
    getProfile: () => void,
    logout: () => void,
}

const initialValue = {
    authenticated: false,
    setAuthenticated: () => { },
    accessToken: '',
    setAccessToken: () => { },
    loginError: false,
    setLoginError: () => { },
    login: () => { },
    me: {
        email: '',
        firstName: '',
        lastName: '',
        referralCode: ''
    },
    setMe: () => { },
    getProfile: () => { },
    logout: () => { },
}

const AuthContext = createContext<IAuthContext>(initialValue)

const AuthProvider = ({ children }: Props) => {
    //Initializing an auth state with false value (unauthenticated)
    const [authenticated, setAuthenticated] = useState(initialValue.authenticated)
    const [accessToken, setAccessToken] = useState('')
    const [loginError, setLoginError] = useState(false)
    const [me, setMe] = useState<Ime>(initialValue.me)

    const login = async (username: string, password: string) => {
        await axios.post(`${BASE_URL}/advisors/login`, {
            username,
            password
        }, {}).then(res => {
            setLoginError(false)
            let access_token = res.data.access_token
            console.log('accessToken', access_token);

            setAccessToken(access_token)
            localStorage.setItem('accessToken', access_token)
            setAuthenticated(true)
            if (access_token) getProfile()
        }).catch(e => {
            setLoginError(true)
            console.log('Login error', e)
            setAuthenticated(false)
        })
    }

    const logout = () => {
        localStorage.removeItem('accessToken')
        setAccessToken('')
        setMe(initialValue.me)
        setAuthenticated(false)
        localStorage.removeItem('me')
    }

    const getProfile = async () => {
        let access_token = localStorage.getItem('accessToken')
        axios.get(`${BASE_URL}/advisors/profile`, {
            headers: {
                authorization: `Bearer ${access_token}`,
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        }).then(async res => {
            setMe(res.data)
            localStorage.setItem('me', JSON.stringify(res.data))
            // console.log(res.data)
            // console.log('ACCESS TOKEN: ', access_token);
        }).catch(e => {
            console.log('profile GET error', e)
            if (e.code === 'ERR_BAD_REQUEST') {
                logout()
            }
        })
    }

    useEffect(() => {
        let access_token = localStorage.getItem('accessToken')
        if (access_token) {
            setAccessToken(access_token)
            setAuthenticated(true)
            getProfile()
        } else {
            setAccessToken('')
            setMe(initialValue.me)
            setAuthenticated(false)
        }
    }, [])


    return (
        <AuthContext.Provider value={{ authenticated, setAuthenticated, login, me, setMe, getProfile, accessToken, setAccessToken, loginError, setLoginError, logout }}>
            {children}
        </AuthContext.Provider>
    )
}

export { AuthContext, AuthProvider }