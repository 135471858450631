import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from './context/AuthContext'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ToastContainer } from 'react-toastify';
import "tw-elements-react/dist/css/tw-elements-react.min.css";

const queryClient = new QueryClient()

const root = document.getElementById('root')!
ReactDOM.createRoot(root).render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <AuthProvider>
        <App />
        <ToastContainer />
        <ToastContainer />
      </AuthProvider>
    </BrowserRouter>
  </QueryClientProvider>
)