import React, { useRef, useState, useEffect } from 'react'

import { ChartPieIcon } from '@heroicons/react/24/solid'
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import { useMutation } from 'react-query'
import { ReportsService } from '../../services/Reports'
import Spinner from '../../components/Spinner'

ChartJS.register(ArcElement, Tooltip);

const Home = () => {

    const options = {
        cutout: 70,
        responsive: true,
        legend: {
            display: false,
            position: "right"
        },
        tooltips: {
            callbacks: {
                label: (tooltipItem: any, data: any) => {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    var index = tooltipItem.index;
                    return dataset.labels[index] + ": " + dataset.data[index];
                }
            }
        }
    };

    const [investmentsByRound, setInvestmentsByRound] = useState<any>([])
    const [referralsByRound, setReferralsByRound] = useState<any>([])
    const [totals, setTotals] = useState<any>([])
    const [isLoaddingTotals, setIsLoadingTotals] = useState<boolean>(true)

    const [isLoaddingReferralsByRound, setIsLoadingReferralsByRound] = useState<boolean>(true)
    const [ReferralsByRoundTableData, setReferralsByRoundTableData] = useState<any>([])
    const [chartDataReferralsByRound, setChartDataReferralsByRound] = useState<any>()

    const [isLoaddingInvestmentsByRound, setIsLoadingInvestmentsByRound] = useState<boolean>(true)
    const [InvestmentsByRoundTableData, setInvestmentsByRoundTableData] = useState<any>([])
    const [chartDataInvestmentsByRound, setChartDataInvestmentsByRound] = useState<any>()


    const chartReferralByRoundRef = useRef(null);
    const chartInvestmentByRounRef = useRef(null);

    const { mutateAsync } = useMutation(ReportsService.getInvestmentsByRound)
    const { mutateAsync: mutateAsyncReferralssByRound } = useMutation(ReportsService.getReferralsByRound)
    const { mutateAsync: mutateAsyncTotals } = useMutation(ReportsService.getTotals)

    const initRequest = async () => {
        await mutateAsync().then(async (r) => {
            setInvestmentsByRound(r)
        })
            .catch((error) => {
                console.log(error.response.data.message);
            })

        await mutateAsyncReferralssByRound().then(async (r) => {
            setReferralsByRound(r)
        })
            .catch((error) => {
                console.log(error.response.data.message);
            })

        setIsLoadingTotals(true)
        await mutateAsyncTotals().then(async (r) => {
            setTotals(r)
            setIsLoadingTotals(false)
        })
            .catch((error) => {
                console.log(error.response.data.message);
                setIsLoadingTotals(false)
            })
    }

    const handleDataReferralsByRoundData = () => {
        let dataChart = {}
        let dataLabels: string[] = []
        let dataAmounts: string[] = []

        if (referralsByRound.length > 0) {
            setReferralsByRoundTableData(referralsByRound)
            referralsByRound.map((r: any) => {
                dataLabels.push(r?.title)
                dataAmounts.push(r?.number_of_referrals)
            })

            dataChart = {
                labels: dataLabels,
                datasets: [
                    {
                        label: '',
                        data: dataAmounts,
                        backgroundColor: [
                            '#0065F9',
                            '#66A3FB',
                            '#99C1FD',
                            '#BCB2FD',
                            '#8476ED',
                            '#6551F3',
                            '#9CEEFF',
                            '#6BE5FF',
                            '#08D4FF',
                        ],
                        borderColor: [
                            'rgb(31 41 55)',
                        ],
                        borderWidth: 0,
                    },
                ],
            }
        } else {
            dataChart = {
                labels: [],
                datasets: [
                    {
                        label: '',
                        data: [],
                        backgroundColor: [
                            '#0065F9',
                            '#66A3FB',
                            '#99C1FD',
                            '#BCB2FD',
                            '#8476ED',
                            '#6551F3',
                            '#9CEEFF',
                            '#6BE5FF',
                            '#08D4FF',
                        ],
                        borderColor: [
                            'rgb(31 41 55)',
                        ],
                        borderWidth: 0,
                    },
                ],
            }
        }

        setChartDataReferralsByRound(dataChart)
        setIsLoadingReferralsByRound(false)
    }

    const handleDataInvestmentsByRoundData = () => {
        let dataChart = {}
        let dataLabels: string[] = []
        let dataAmounts: string[] = []

        if (investmentsByRound.length > 0) {
            setInvestmentsByRoundTableData(investmentsByRound)
            investmentsByRound.map((r: any) => {
                dataLabels.push(r?.title)
                dataAmounts.push(r?.average_investment.native)
            })

            dataChart = {
                labels: dataLabels,
                datasets: [
                    {
                        label: '',
                        data: dataAmounts,
                        backgroundColor: [
                            '#0065F9',
                            '#66A3FB',
                            '#99C1FD',
                            '#BCB2FD',
                            '#8476ED',
                            '#6551F3',
                            '#9CEEFF',
                            '#6BE5FF',
                            '#08D4FF',
                        ],
                        borderColor: [
                            'rgb(31 41 55)',
                        ],
                        borderWidth: 0,
                    },
                ],
            }
        } else {
            dataChart = {
                labels: [],
                datasets: [
                    {
                        label: '',
                        data: [],
                        backgroundColor: [
                            '#0065F9',
                            '#66A3FB',
                            '#99C1FD',
                            '#BCB2FD',
                            '#8476ED',
                            '#6551F3',
                            '#9CEEFF',
                            '#6BE5FF',
                            '#08D4FF',
                        ],
                        borderColor: [
                            'rgb(31 41 55)',
                        ],
                        borderWidth: 0,
                    },
                ],
            }
        }

        setChartDataInvestmentsByRound(dataChart)
        setIsLoadingInvestmentsByRound(false)
    }

    useEffect(() => {
        initRequest()
    }, [])

    useEffect(() => {
        handleDataReferralsByRoundData()
    }, [referralsByRound])

    useEffect(() => {
        handleDataInvestmentsByRoundData()
    }, [investmentsByRound])

    return (
        <>
            <header className="shadow">
                <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8 flex flex-row items-center">
                    <div className='flex flex-col'>
                        <h1 className="text-3xl font-bold tracking-tight text-white mt-0 mb-3">Dashboard</h1>
                    </div>
                </div>
            </header>
            <main>
                <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 xs:px-4">

                    <div className='grid xs:grid-cols-1 md:grid-cols-4 gap-10 mb-10'>
                        <div className='flex flex-col justify-center transform overflow-hidden rounded-2xl bg-gray-800 p-6 text-left align-middle transition-all opacity-100 scale-100'>
                            <div className='flex flex-row items-center pb-5'>
                                <div className='flex flex-row items-center'>
                                    <h3 className='text-white'>Monto total valorizado</h3>
                                </div>
                            </div>

                            <div className='flex flex-row items-center justify-center text-white text-2xl'>
                                {isLoaddingTotals ? (
                                    <div className="w-full h-8 flex justify-center items-center">
                                        <Spinner />
                                    </div>
                                ) : (
                                    totals?.total_amount?.formatted
                                )}
                            </div>
                        </div>

                        <div className='flex flex-col justify-center transform overflow-hidden rounded-2xl bg-gray-800 p-6 text-left align-middle transition-all opacity-100 scale-100'>
                            <div className='flex flex-row items-center pb-5'>
                                <div className='flex flex-row items-center'>
                                    <h3 className='text-white'>Monto total invertido</h3>
                                </div>
                            </div>

                            <div className='flex flex-row items-center justify-center text-white text-2xl'>
                                {isLoaddingTotals ? (
                                    <div className="w-full h-8 flex justify-center items-center">
                                        <Spinner />
                                    </div>
                                ) : (
                                    totals?.total_capital?.formatted
                                )}
                            </div>
                        </div>

                        <div className='flex flex-col justify-center transform overflow-hidden rounded-2xl bg-gray-800 p-6 text-left align-middle transition-all opacity-100 scale-100'>
                            <div className='flex flex-row items-center pb-5'>
                                <div className='flex flex-row items-center'>
                                    <h3 className='text-white'>Monto total disponible</h3>
                                </div>
                            </div>

                            <div className='flex flex-row items-center justify-center text-white text-2xl'>
                                {isLoaddingTotals ? (
                                    <div className="w-full h-8 flex justify-center items-center">
                                        <Spinner />
                                    </div>
                                ) : (
                                    totals?.total_bank_amount?.formatted
                                )}
                            </div>
                        </div>

                        <div className='flex flex-col justify-center transform overflow-hidden rounded-2xl bg-gray-800 p-6 text-left align-middle transition-all opacity-100 scale-100'>
                            <div className='flex flex-row items-center pb-5'>
                                <div className='flex flex-row items-center'>
                                    <h3 className='text-white'>Monto total intereses cobrados</h3>
                                </div>
                            </div>

                            <div className='flex flex-row items-center justify-center text-white text-2xl'>
                                {isLoaddingTotals ? (
                                    <div className="w-full h-8 flex justify-center items-center">
                                        <Spinner />
                                    </div>
                                ) : (
                                    totals?.realized_profit?.formatted
                                )}
                            </div>
                        </div>

                    </div>

                    <div className='grid xs:grid-cols-1 md:grid-cols-2 gap-10'>
                        <div className='flex flex-col justify-center transform overflow-hidden rounded-2xl bg-gray-800 p-6 text-left align-middle transition-all opacity-100 scale-100'>
                            <div className='flex flex-row'>
                                <div className='flex flex-row center'>
                                    <ChartPieIcon className="text-white mr-2 h-6 w-6" aria-hidden="true" />
                                    <h3 className='text-white pb-5'>Cantidad de clientes por ronda</h3>
                                </div>
                            </div>

                            <div className='flex xs:flex-col md:flex-row gap-10 items-center'>

                                {isLoaddingReferralsByRound ? (
                                    <div className="w-full h-48 flex justify-center items-center">
                                        <Spinner />
                                    </div>
                                ) : (

                                    ReferralsByRoundTableData.length > 0 ? (
                                        <>
                                            <div className='relative xs:w-full md:w-1/3'>
                                                <div className='relative z1'>
                                                    <Doughnut
                                                        data={chartDataReferralsByRound}
                                                        options={options}
                                                        ref={chartReferralByRoundRef}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full flex flex-col">
                                                <div className='flex flex-row w-full border-b-2 border-blue-400 py-2'>
                                                    <span className='w-1/2 text-xs text-blue-400'>Detalle</span>
                                                    <span className='w-1/2 text-xs text-blue-400 text-right'>Clientes</span>
                                                </div>
                                                <div className='h-40 overflow-auto'>
                                                    {ReferralsByRoundTableData.length > 0 && (
                                                        ReferralsByRoundTableData.map((d: any, k: any) => (
                                                            <div key={k} className='flex flex-row w-full border-b-2 border-gray-700 py-3'>
                                                                <span className='w-1/2 text-xs text-white'>{d?.title}</span>
                                                                <span className='w-1/2 text-xs text-white text-right'>{d?.number_of_referrals}</span>
                                                            </div>
                                                        ))
                                                    )}

                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="w-full h-48 flex justify-center items-center text-white">
                                            No existen datos para mostrar
                                        </div>
                                    )
                                )}

                            </div>
                        </div>

                        <div className='flex flex-col justify-center transform overflow-hidden rounded-2xl bg-gray-800 p-6 text-left align-middle transition-all opacity-100 scale-100'>
                            <div className='flex flex-row'>
                                <div className='flex flex-row center'>
                                    <ChartPieIcon className="text-white mr-2 h-6 w-6" aria-hidden="true" />
                                    <h3 className='text-white pb-5'>Inversiones promedio por ronda</h3>
                                </div>
                            </div>

                            <div className='flex xs:flex-col md:flex-row gap-10 items-center'>

                                {isLoaddingInvestmentsByRound ? (
                                    <div className="w-full h-48 flex justify-center items-center">
                                        <Spinner />
                                    </div>
                                ) : (

                                    InvestmentsByRoundTableData.length > 0 ? (
                                        <>
                                            <div className='relative xs:w-full md:w-1/3'>
                                                <div className='relative z1'>
                                                    <Doughnut
                                                        data={chartDataInvestmentsByRound}
                                                        options={options}
                                                        ref={chartInvestmentByRounRef}
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-full flex flex-col">
                                                <div className='flex flex-row w-full border-b-2 border-blue-400 py-2'>
                                                    <span className='w-1/2 text-xs text-blue-400'>Detalle</span>
                                                    <span className='w-1/2 text-xs text-blue-400 text-right'>Monto promedio</span>
                                                </div>
                                                <div className='h-40 overflow-auto'>
                                                    {InvestmentsByRoundTableData.length > 0 && (
                                                        InvestmentsByRoundTableData.map((d: any, k: any) => (
                                                            <div key={k} className='flex flex-row w-full border-b-2 border-gray-700 py-3'>
                                                                <span className='w-1/2 text-xs text-white'>{d?.title}</span>
                                                                <span className='w-1/2 text-xs text-white text-right'>{d?.average_investment?.formatted}</span>
                                                            </div>
                                                        ))
                                                    )}

                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="w-full h-48 flex justify-center items-center text-white">
                                            No existen datos para mostrar
                                        </div>
                                    )
                                )}

                            </div>
                        </div>

                    </div>

                </div>
            </main>
        </>
    )
}

export default Home