import { coreApiAuthorizedInstance } from './axiosInstance'
import IProspectoUser from '../interfaces/core-api/prospecto'

export class ProspectoService {
    public static getPotentialUsers = async (): Promise<any> => {
        return await coreApiAuthorizedInstance.get(`/advisors/potential-user`, {
            validateStatus: function (status: number) {
                return status < 300
            },
        })
    }

    public static addPotentialUser = async (data: IProspectoUser): Promise<any> => {
        return await coreApiAuthorizedInstance.post(`/advisors/potential-user`, data, {
            validateStatus: function (status: number) {
                return status < 300
            }
        })
    }
}
