import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { Outlet } from 'react-router-dom'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import Logo from '../assets/images/logo.png'
import { AuthContext } from '../context/AuthContext'
import { ClipboardDocumentIcon } from '@heroicons/react/24/outline'

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Layout = () => {

    const location = useLocation()
    const { logout, me } = useContext(AuthContext)
    const navigate = useNavigate();
    const [user, setUser] = useState<any>()

    const navigation = [
        { name: 'Dashboard', href: '/', current: location.pathname === '/' },
        { name: 'Mis clientes', href: '/mis-clientes', current: location.pathname === '/mis-clientes' || location.pathname === '/cliente' },
        { name: 'Prospecto', href: '/prospecto', current: location.pathname === '/prospecto' },
    ]

    const rtrim = (str: string) => {
        if (!str) return str;
        return str.replace(/\s+$/g, '');
    }

    const getInitialsFromName = () => {
        let name_str = rtrim(`${me?.firstName} ${me?.lastName}`)
        let split_string = name_str.split(' ')
        let initials = ''
        if (split_string.length > 1) {
            initials = `${split_string[0][0]}${split_string[1][0]}`
        }
        if (split_string.length == 1) {
            initials = `${split_string[0][0]}`
        }
        return initials
    }

    const classNames = (...classes: any) => {
        return classes.filter(Boolean).join(' ')
    }

    const handleLogout = () => {
        logout()
    }

    const copyReferal = async () => {
        let referal = me?.referralCode
        await navigator.clipboard.writeText(referal)

        toast.success('Código de referido copiado con éxito', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    }

    return (
        <div className="min-h-full bg-gray-700">
            <Disclosure as="nav" className="bg-gray-800">
                {({ open }) => (
                    <>
                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                            <div className="flex h-16 items-center justify-between">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        <img
                                            className='h-32'
                                            src={Logo}
                                            alt="Yont"
                                        />
                                    </div>
                                    <div className="hidden md:block">
                                        <div className="ml-10 flex items-baseline space-x-4">
                                            {navigation.map((item) => (
                                                <Link
                                                    key={item.name}
                                                    // href={item.href}
                                                    to={item.href}
                                                    className={classNames(
                                                        item.current
                                                            ? 'bg-gray-900 text-white'
                                                            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                                        'rounded-md px-3 py-2 text-sm font-medium'
                                                    )}
                                                    aria-current={item.current ? 'page' : undefined}
                                                >
                                                    {item.name}
                                                </Link>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="hidden md:block ml-auto">
                                    <div className="ml-4 flex items-center md:ml-6">
                                        {/* Profile dropdown */}
                                        <div className="ml-auto text-white flex center row text-sm">
                                            <span className="py-2">Éste es tu código de referido</span>
                                            <span className="ml-2 rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm flex center">
                                                <span>{me?.referralCode}</span>
                                                <span className="ml-3 cursor-pointer" onClick={copyReferal}><ClipboardDocumentIcon className="h-5 w-5" aria-hidden="true" /></span>
                                            </span>
                                        </div>
                                        <Menu as="div" className="relative ml-3">
                                            <div>
                                                <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                                    <span className="absolute -inset-1.5" />
                                                    <span className="sr-only">Open user menu</span>
                                                    {/* <img className="h-8 w-8 rounded-full" src={user.imageUrl} alt="" /> */}
                                                    <div className="bg-blue-500 text-white w-8 h-8 rounded-full justify-center items-center pt-0.5 text-xl">{getInitialsFromName()}</div>
                                                </Menu.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <Menu.Item>
                                                        <button onClick={handleLogout}
                                                            className='block px-4 py-2 text-sm text-gray-700'
                                                        >
                                                            Cerrar sesión
                                                        </button>
                                                    </Menu.Item>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    </div>
                                </div>
                                {/* Mobile menu button */}
                                <div className="-mr-2 md:hidden">
                                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                        <span className="absolute -inset-0.5" />
                                        <span className="sr-only">Open main menu</span>
                                        {open ? (
                                            <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                        ) : (
                                            <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                        )}
                                    </Disclosure.Button>
                                </div>
                            </div>
                        </div>

                        <Disclosure.Panel className="md:hidden">
                            <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3 flex-col flex">
                                {navigation.map((item) => (
                                    <Link
                                        key={item.name}
                                        // href={item.href}
                                        to={item.href}
                                        className={classNames(
                                            item.current
                                                ? 'bg-gray-900 text-white'
                                                : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                            'rounded-md px-3 py-2 text-sm font-medium'
                                        )}
                                        aria-current={item.current ? 'page' : undefined}
                                    >
                                        {item.name}
                                    </Link>
                                ))}
                            </div>
                            <div className="border-t border-gray-700 pb-3 pt-4 flex justify-center items-center">
                                <div className="text-white flex center row text-sm">
                                    <span className="py-2">Éste es tu código de referido</span>
                                    <span className="ml-2 rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm flex center">
                                        <span>{me?.referralCode}</span>
                                        <span className="ml-3 cursor-pointer" onClick={copyReferal}><ClipboardDocumentIcon className="h-5 w-5" aria-hidden="true" /></span>
                                    </span>
                                </div>
                            </div>
                            <div className="border-t border-gray-700 pb-3 pt-4">
                                <div className="flex items-center px-5">
                                    <div className="flex-shrink-0">
                                        <div className="bg-blue-500 text-white w-8 h-8 rounded-full justify-center items-center pl-1.5 pt-1 text-md">{getInitialsFromName()}</div>
                                    </div>
                                    <div className="ml-3">
                                        <div className="text-base font-medium leading-none text-white mb-1">{me.firstName} {me.lastName}</div>
                                        <div className="text-sm font-medium leading-none text-gray-400">{me.email}</div>
                                    </div>
                                </div>
                                <div className="mt-5 space-y-1 pr-2 pl-12">
                                    <button onClick={handleLogout}
                                        className='block px-4 py-2 text-sm text-white'
                                    >
                                        Cerrar sesión
                                    </button>
                                </div>
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
            <Outlet />
        </div>
    )
}

export default Layout